var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "px-2" }, [
    _c("h4", { staticClass: "mb-2 text-uppercase" }, [
      _vm._v("Program Details")
    ]),
    _c(
      "div",
      { staticClass: "d-flex align-center" },
      [
        _c("v-avatar", { attrs: { size: "48" } }, [
          _c("img", { attrs: { src: _vm.program.icon_filepath } })
        ]),
        _c("h4", { staticClass: "ml-2" }, [_vm._v(_vm._s(_vm.program.name))])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d-flex mt-3 align-center" },
      [
        _c("v-avatar", { attrs: { size: "48" } }, [
          _c("img", {
            attrs: { src: _vm.student.instructor.profile_photo_url }
          })
        ]),
        _c("h4", { staticClass: "ml-2" }, [
          _vm._v(_vm._s(_vm.student.instructor.name))
        ]),
        _c(
          "v-chip",
          { staticClass: "ml-2 text-uppercase", attrs: { small: "" } },
          [_vm._v("Instructor")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d-flex mt-3 align-center" },
      [
        _c(
          "v-avatar",
          { attrs: { size: "48" } },
          [
            _c("v-icon", { attrs: { size: "40" } }, [
              _vm._v("mdi-calendar-check")
            ])
          ],
          1
        ),
        _c("div", { staticClass: "ml-2" }, [
          _c("h4", [_vm._v(_vm._s(_vm.session.name))]),
          _c("h4", { staticClass: "font-weight-regular" }, [
            _vm._v(
              " " +
                _vm._s(_vm.session.start_date) +
                " - " +
                _vm._s(_vm.session.end_date) +
                " "
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }