<template>
    <div class="px-2">
        <h4 class="mb-2 text-uppercase">Program Details</h4>
        <div class="d-flex align-center">
            <v-avatar size="48">
                <img :src="program.icon_filepath" />
            </v-avatar>
            <h4 class="ml-2">{{ program.name }}</h4>
        </div>
        <div class="d-flex mt-3 align-center">
            <v-avatar size="48">
                <img :src="student.instructor.profile_photo_url" />
            </v-avatar>
            <h4 class="ml-2">{{ student.instructor.name }}</h4>
            <v-chip class="ml-2 text-uppercase" small>Instructor</v-chip>
        </div>
        <div class="d-flex mt-3 align-center">
            <v-avatar size="48">
                <v-icon size="40">mdi-calendar-check</v-icon>
            </v-avatar>
            <div class="ml-2">
                <h4>{{ session.name }}</h4>
                <h4 class="font-weight-regular">
                    {{ session.start_date }} - {{ session.end_date }}
                </h4>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "applicant-profile-program",
    components: {},
    props: {
        student: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        program() {
            return this.student.programs[0];
        },
        session() {
            return this.student.sessions[0];
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>